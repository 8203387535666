const Map = () => {
  return (
    <div className="bg-white h-[25rem] md:px-[6rem]">
      <iframe
        title="Location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28703.044484485697!2d-80.14561680237385!3d25.93914628593195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9acfee073549d%3A0xf4e74db7a5da487a!2sSunny%20Isles%20Beach%2C%20FL%2033160%2C%20USA!5e0!3m2!1sen!2sin!4v1710404180991!5m2!1sen!2sin"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        className="w-full rounded h-full bg-white"
      ></iframe>
    </div>
  );
};

export default Map;
