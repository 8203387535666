// Dependencies
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// Local Files
import EmailForm from "./suComponents/EmailForm";
import Map from "./suComponents/Map";
// import ImageRow from "../Home/SubComponents/ImageRow";

import { scrollTop } from "../../utils/controllers";
import { updateTab } from "../../store/curTabSlice";
import { FaHome } from "react-icons/fa";

const Contact = () => {
  const dispatch = useDispatch();
  dispatch(updateTab("Contact"));

  const location = useLocation();
  const { offset } = location.state || {};
  scrollTop(offset);

  return (
    <>
      <div className="flex flex-col justify-start px-[3rem] py-[5rem] bg-white w-full">
        <Map />
        <EmailForm />
      </div>
    </>
  );
};

export default Contact;
