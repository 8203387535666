// Dependencies
import { useDispatch } from "react-redux";

// Local Files
import { updateTab } from "../../store/curTabSlice";
import { scrollTop } from "../../utils/controllers";
import "./Home.css";
import graphic from "../../globalAssets/antivirus.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { Antivirusdata } from "../Data/products";
import ProductCards from "../ShopPage/SubComponents.tsx/ProductCards";

const Home = () => {
  const dispatch = useDispatch();
  dispatch(updateTab("Home"));
  const navigate=useNavigate();
  const data=Antivirusdata.slice(0,8);
  scrollTop();

  return (
    <div className="home-wrapper">
      <div className="custom-container">
        <div className="home-content-wrapper">
          <h1>Best Antivirus Provider</h1>
          <p className="home-content-description">
            Welcome to our website dedicated to providing top-notch antivirus
            solutions for your digital security needs. At our site, you'll find
            a comprehensive range of cutting-edge antivirus software designed to
            safeguard your devices against malware, ransomware, phishing
            attacks, and other online threats. With our powerful threat
            detection technology, real-time protection, and user-friendly
            interface, you can browse, work, and connect online with confidence,
            knowing that your data and privacy are fully protected. Explore our
            offerings today and take the first step towards a safer and more
            secure digital experience. Free antivirus software are now hard to
            find, many who used to offer free antivirus now make you pay for
            their software. Some good news is there is still some fantastic free
            antivirus software. Protecting your computer from viruses has always
            been important but is now more vital than ever in today’s ever
            evolving technological age. Searching for the best free antivirus
            isn’t always easy, finding the right solution to suit your needs and
            budget can be a hassle. Here are some of the best free antivirus
            software providers 2024. We receive advertising revenue from some
            antivirus software, more information here, this may impact how and
            where their software is featured. Use our comparison tools to find
            the best antivirus for you.
          </p>
          <div className="flex flex-wrap justify-center py-5 bg-white">
                    {data.map((detail: any) => (
                      <ProductCards {...detail} />
                    ))}
          </div>
            {/*  */}
            <div className="show-more">
              <button onClick={()=>{
                navigate('/Store');
              }} >Show More Products</button>
            </div>
          </div>
          </div>
          </div>
          
  );
};

export default Home;
