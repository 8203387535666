import React from "react";

import ppo from "./assets/random.jpg";

const Specialization = () => {
  return (
    <div className="bg-white h-auto lg:h-[40rem] flex lg:flex-row flex-col items-center justify-evenly border-black p-[2rem] custom-wrapper">
      <div className="w-[38em] md:p-[3rem] mt-[3rem] md:mt-[0.1rem] px-[2rem] h-[28rem] bg-center cover py-[2rem] lg:inline-block hidden">
        <img
          className=" "
          src="https://img.freepik.com/free-vector/cyber-attack-concept-illustration_114360-2016.jpg?w=740&t=st=1711821562~exp=1711822162~hmac=0d00307bf2737f0c6213fd0da4a7d89309f5e534288d4c77026195f9878fb7cb"
        />
      </div>
      <div className="lg:py-[1rem] py-[1rem] px-[1rem] h-auto bg-gray-200 mt-[1rem] p-[1rem] ">
        <div className="py-[1rem] md:py-[0.1rem]">
          <h1 className="md:text-4xl  text-2xl font-large font-serif px-[2rem]">
            <span className="lg:text-start "></span> About SecureShields
          </h1>
        </div>

        <p className="text-1xl text-medium  font-serif md:mt-[2rem] md:w-[35rem] w-auto px-[2rem] custom-text ">
          SecureShields is a leading provider of comprehensive cybersecurity solutions specializing in antivirus
          software and e-commerce security. With a steadfast commitment to safeguarding businesses and individuals
          against the ever-evolving threat landscape, SecureShields offers state-of-the-art products and services
          tailored to meet the diverse needs of our clients.Our mission at SecureShields is to empower businesses and
          individuals with robust cybersecurity solutions that defend against cyber threats, protect sensitive data, and
          uphold digital integrity. We strive to deliver innovative, user-friendly antivirus software and e-commerce
          security solutions that inspire trust and confidence in the digital realm.
        </p>
      </div>
    </div>
  );
};

export default Specialization;
