import React from "react";
import './Define.css'
import p1 from './assets/bag (1).jpg'
import p2 from "./assets/cus.jpg"
import p3 from "./assets/lug.jpg"


const content = [
  {
    title: "Customer Support",
    photo: p2,
    description:
      "At SecureShields, we understand that reliable customer support is essential for ensuring a seamless experience with our antivirus products. Our dedicated support team is committed to assisting you with any questions, concerns, or technical issues you may encounter. Whether you need help installing the software, troubleshooting a problem, or simply have a question about our products, we're here to help",
  },
  {
    title: " Malware Protection",
    photo:
      "https://img.freepik.com/free-photo/young-adult-businessman-sitting-desk-typing-generated-by-ai_188544-26243.jpg?t=st=1711822055~exp=1711825655~hmac=f95bff180143cdae60344f5761e7d533943fa71a0fed9d6fe4e7371cc0c3bd7e&w=826",
    description:
      "SecureShields Antivirus employs advanced scanning algorithms to detect and remove a wide range of malware threats, including viruses, ransomware, spyware, Trojans, and more. Our proactive approach ensures that even the most sophisticated malware strains are identified and neutralized before they can cause harm to your system..",
  },
  {
    title: "Confidentiality and Security",
    photo:
      "https://img.freepik.com/free-photo/person-utilizing-ai-tools-daily-tasks_23-2150879482.jpg?w=826&t=st=1711822090~exp=1711822690~hmac=4e08bcf7c6bf46bbe43f3da61d3ce8a97463cb7e1c318a34fceeabc3c76c4411",
    description:
      "You, the user of this website herby acknowledge and agree that trustedantiviruscompare.com and its partners or affiliates cannot be held liable for damages that may occur as a result of your confidential information being transmitted to or from this website over the internet and that all communications are deemed at your own risk.",
  },
];


const Define = () => {
  return (
    <div className="bg-white h-auto">
      <div className="flex flex-col justify-center items-center">
        <div className="p-[2rem]">
          <h1 className="md:text-5xl text-4xl font-serif font-bold text-black">
            Our <span className="">Services</span>{" "}
          </h1>
        </div>
        {content.map((e) => (
          <div className="flex xl:h-[28rem] h-auto flex-col md:flex-col xl:flex-row items-center justify-between p-[1rem]  border-b-2">
            {/* //Photo section */}
            <div className="flex flex-col justify-center items-center xl:p-[5rem] p-[1rem] ">
              <div className=" bg h-[19rem] w-[25rem] mt-[2rem]  ">
                <img src={e.photo} />
              </div>
            </div>
            {/* //paragraph section */}
            <div className="xl:p-[2rem]  px-[3rem] xl:mb-[5rem] flex flex-col mb-[2rem]  ">
              <div className="lg:py-[2rem]">
                <h2 className="font-serif font-bold xl:text-start  text-center text-2xl">{e.title}</h2>
              </div>
              <p className="font-serif font-thin text-gray-500 xl:text-start text-center font-1xl ">{e.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Define;
