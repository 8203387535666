// Dependencies
import {
  Navbar,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Image,
} from "@nextui-org/react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// Local Files
import "./NavBar.css";
import { RootState } from "../store/store";
import { updateNavStatus } from "../store/navOpenStatusSlice";
import { updateToLoginStatus } from "../store/toLoginSlice";
import { getCookie } from "../utils/cookies";
const menuItems = ["Home", "Store", "About", "Contact"];

const NavBar = () => {
  const isLoggedIn = getCookie("token") ? true : false;
  const curTab = useSelector((state: RootState) => state.curTab.value);
  const navOpenStatus = useSelector((state: RootState) => state.navOpenStatus.value);
  const dispatch = useDispatch();
  const navigate=useNavigate();
  return (
    <Navbar
      isMenuOpen={navOpenStatus}
      onMenuOpenChange={() => {
        dispatch(updateNavStatus(!navOpenStatus));
      }}
      className="h-[5rem] nav z-[200]"
      maxWidth="xl"
      shouldHideOnScroll
      classNames={{ base: "bg-[rgba(0,0,0,0.4)]" }}
    >
      <NavbarContent>
        <NavbarMenuToggle aria-label={navOpenStatus ? "Close menu" : "Open menu"} className="lg:hidden text-white" />
        <div className="lg:block">
          {/* <Image width={60} src={icon} alt="logo" radius="none" /> */}
          <p onClick={()=>{
            navigate('/home');
          }} style={{color:'white',fontSize:'30px',cursor:'pointer'}}>SecureShields</p>
        </div>
      </NavbarContent>
      {/* <NavbarContent className="lg:hidden logo">
        <Link to="../Home">
          <div className="bg-white p-[1rem] flex justify-center items-center max-h-[5rem] ribbon">
          <p style={{color:'white',fontSize:'30px',marginLeft:'20px'}}>SecureShields</p>
          </div>
        </Link>
      </NavbarContent> */}

      <NavbarContent className="hidden lg:flex" justify="center">
        <NavbarItem>
          <Link
            to="../Home"
            className={curTab === "Home" ? "active navActive flex flex-col px-[1rem]" : "notActive px-[1rem]"}
          >
            HOME
          </Link>
        </NavbarItem>

        <NavbarItem>
          <Link
            to="../Store"
            className={curTab === "Store" ? "active navActive flex flex-col px-[1rem]" : "notActive px-[1rem]"}
          >
            STORE
          </Link>
        </NavbarItem>

        <NavbarItem>
          <Link
            to="../About"
            className={curTab === "About" ? "active navActive flex flex-col px-[1rem]" : "notActive px-[1rem]"}
          >
            ABOUT
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link
            to="../Contact"
            className={curTab === "Contact" ? "active navActive flex flex-col px-[1rem]" : "notActive px-[1rem]"}
          >
            CONTACT US
          </Link>
        </NavbarItem>
        
        </NavbarContent>
        <NavbarMenu className="mt-[3rem] bg-black text-white z-[200]">
          {menuItems.map((item, index) => {
            return (
              <NavbarMenuItem key={`${item}-${index}`}>
                <Link
                  className={curTab === item ? "nav-active" : "notActive"}
                  to={index === 4 ? "/Checkout" : `../${item}`}
                  onClick={() => {
                    dispatch(updateNavStatus(!navOpenStatus));
                    dispatch(updateToLoginStatus(item === "Log In" ? true : false));
                  }}
                >
                  {item}
                </Link>
              </NavbarMenuItem>
            );
          })}
        </NavbarMenu>
    </Navbar>
  );
};

export default NavBar;
