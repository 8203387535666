import React from "react";

const Privacy = () => {
  return (
    <div className="flex flex-row justify-center items-start bg-white h-auto p-[5rem] gap-[4rem]">
      <div className="flex flex-col items-start justify-center">
        <div>
          <h1 className="text-3xl font-semibold text-gray-600 py-[1rem]">Privacy Policy</h1>
          <p className="text-gray-500 py-[1rem]">
            "Our SecureShields web servers prioritize user privacy and security. While we do capture domain names of
            visitors for analytical purposes, we do not retain any personal email addresses. Moreover, we do not track
            individual browsing activities or store specific page visits. Any data collected, such as through contact
            form submissions or questionnaire responses, is utilized solely for review purposes and promptly discarded
            thereafter. Our commitment is to enhance user experience and provide comprehensive evaluations of our
            partners' products, while respecting user privacy at every step."
          </p>
        </div>
        <div>
          <h1 className="text-2xl font-semibold text-gray-600 py-[1rem]">Policy on website cookies</h1>
          <p className="text-gray-500 py-[1rem]">
            "At SecureShields.com, we prioritize the security of consumer data by employing cutting-edge website encryption
            technology for all data exchanges. If you have any concerns regarding our compliance with established
            guidelines, we encourage you to reach out to us promptly. You can contact us via our contact form or
            directly at admin@trustedSecureShieldscompare.com. For further details on our Cookie Policy, please refer to the
            information provided here."
          </p>
        </div>
        <div>
          <h1 className="text-2xl font-semibold text-gray-600 py-[1rem]">SecureShields Security</h1>
          <p className="text-gray-500 py-[1rem]">
            "At SecureShields.com, we utilize cookies to enhance the browsing experience of our visitors.
            These small text files stored by your browser help us ensure that you receive tailored information and do
            not encounter repetitive content. It's important to note that cookies solely contain data related to your
            computer usage and do not include any personal information. Any updates or revisions to our cookie policy
            will be promptly communicated on this platform. By accessing our website, you acknowledge and agree to abide
            by our cookie policy during each visit."
          </p>
          <p className="text-gray-500 py-[1rem]">
            At any point, you have the right to contact SecureShields.com if you feel that the site is not
            complying with its own privacy terms, please get in touch via our contact form. Alternatively, you can
            contact us directly via admin@SecureShields.com
          </p>
        </div>
      </div>
      <div className=" flex-col justify-center items-center border-1 lg:inline-block hidden p-[1rem] w-[60rem]">
        <h1 className="text-start font-semibold text-gray-500">The Top 10 SecureShields</h1>
        <p className="text-start opacity-70 py-[1rem] ">
          We do our best to keep all information up to date and accurate but cannot guarantee this due to the constantly
          changing SecureShields landscape. We receive advertising revenue from some SecureShields software we feature to
          showcase their products. This impacts the location and order in which the companies are presented plus
          additonal banner advertising and site behavior such as direct download buttons.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
