import { useSelector } from "react-redux";
import "./Product.css";
import { useNavigate } from "react-router-dom";
import { Badge, Image } from "@nextui-org/react";
import { individualProductType } from "../../../utils/types";
import { RootState } from "../../../store/store";

const ProductCards = (props: any) => {
  const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  const createdDate = new Date(props.created_at);
  const apiUrl = useSelector((state: RootState) => state.apiConfig.value);
  const navigate = useNavigate();
  let content = "";
  let className = "rounded-none ";
  let color: "primary" | "danger" | "warning" | "default" | "secondary" | "success" | undefined = "primary";

  if (props.quantity < props.minimumOrder) {
    content = "Out Of Stock";
    className += "right-[3.1rem] top-[1rem]";
    color = "danger";
  } else if (props.discountPercent >= 50) {
    content = "Sale";
    className += "right-[1.45rem] top-[1rem]";
    color = "warning";
  } else if (createdDate >= sevenDaysAgo) {
    content = "New Arrival";
    className += "right-[2.8rem] top-[1rem]";
    color = "primary";
  }

  return (
    <div
      className="productCard w-[15rem] border m-3 transition-all cursor-pointer rounded-xl"
      // onClick={() => navigate(`/ProductDetails/${props.title}/${props.item_id}`)}
    >
      <Badge
        content={content}
        color={color}
        showOutline={false}
        className={className}
        isInvisible={content === ""}
        variant="shadow"
      >
        <div className="h-[16.5rem] w-[15rem]">
          <Image
            className={content === "Out Of Stock" ? "grayscale rounded-xl" : "rounded-xl"}
            src={props.imageUrl}
            radius="none"
            loading="lazy"
            onClick={()=>navigate('/Store')}
          />
        </div>
      </Badge>
      <div className="textPart bg-white p-3 ">
        <div>
          <p className="font-bold opacity-60">{props.brand}</p>
          <p className="">{props.title}</p>
        </div>

        <div className="flex space-x-2 items-center">
          {props.discountedPrice === props.price || props.discountPercent === 0 ? (
            <p className="font-semibold">${props.price}</p>
          ) : (
            <>
              <p className="font-semibold">${props.discountedPrice}</p>
              <p className="opacity-50 line-through">${props.price}</p>
              <p
                className={
                  content === "Out Of Stock" ? "text-default-500 font-semibold" : "text-green-600 font-semibold"
                }
              >
                {props.discountPercent}% Off
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCards;
